/** IMPORTS **/
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Josefin+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

/*** GLOBAL STYLES ***/
:root {
  --white : #ffffff;
  --offwhite : #fefefe;
  --yellow : #CCA564;
  --green : #009688;
  --black : #2f2f2f;
  --gray : #303030;
  --font-header : 'Cardo', serif;
  --font-text : 'Josefin Sans', sans-serif;
  --doc-height : 100vh;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--offwhite)!important;
}
* {
  color: var(--black);
  font-family: var(--font-text);
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-header);
  font-size: 22px;
  text-align: center;
}
h1{
  font-size: 40px;
}
h2{
  font-size: 35px;
}
p{
  font-size: 18px;
}
p.sub {
  font-size: 20px;
  text-align: center;
}
a {
  color: var(--green)!important;
  text-decoration: none!important;
}
a:hover {
  color: var(--green)!important;
}

/*** LAYOUT ***/
.container.full-width {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
}
.section {
  position: relative;
  padding: 50px 0;
  background-color: var(--offwhite);
  overflow-x: hidden;
}
.text-center {
  text-align: center;
}

/*** CAROUSEL ***/

.owl-theme.owl-carousel .owl-nav {
  position: absolute;
  top: calc(50% - 33px);
  width: 110%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -5%;
}
.owl-theme.owl-carousel .owl-nav button span{
  font-size: 50px;
  color: var(--gray);
}
.owl-theme.owl-carousel .owl-nav button:hover{
  background: rgba(0,0,0,0);
}
.owl-theme.owl-carousel .owl-dots {
  margin-top: 20px;
}
.owl-theme.owl-carousel .owl-dots .owl-dot span {
  background: var(--gray);
}
.owl-theme.owl-carousel .owl-dots .owl-dot.active span {
  background: var(--green);
}
/*** END GLOBAL STYLES ***/
/*** NAV SECTIOON ***/
.section.navigation {
  background: rgba(255,255,255, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  overflow: visible;
}
.section.navigation p.logo{
  font-family: var(--font-header);
  font-size: 40px;
  margin-bottom: 0;
}
.section.navigation p.sub{
  text-align: left;
  font-family: var(--font-header);
  margin-bottom: 0;
}
.section.navigation .main-menu ul{
  display: flex;
  justify-content: flex-end;
  list-style: none;
}
.section.navigation .main-menu ul li{
  margin-right: 40px;
}
.section.navigation .main-menu ul li a{
  font-family: var(--font-header);
  font-size: 20px;
  color: var(--black)!important;
  position: relative;
}
.section.navigation .main-menu ul li a:after{
  content: '';
  width: 20px;
  height: 3px;
  background-color: var(--green);
  position: absolute;
  bottom: 0;
  left: 0;
  transition-duration: .3s;
}
.section.navigation .main-menu ul li a:hover:after{
  width: 100%;
}

.section.navigation .mobile-menu {
  display: none;
}
.section.navigation .mobile-menu .toggle-container{
  width: fit-content;
  margin-left: auto;
}
.section.navigation .mobile-menu .nav-toggle .bar{
  width: 35px;
  height: 5px;
  background-color: var(--black);
  margin: 6px 0;
  transition: 0.4s;
}
.section.navigation.active .mobile-menu .nav-toggle .bar.top {
  transform: translate(0, 11px) rotate(45deg);
}
.section.navigation.active .mobile-menu .nav-toggle .bar.middle {
  transform: rotate(-45deg);
}
.section.navigation.active .mobile-menu .nav-toggle .bar.bottom {
  transform: translate(0, -11px) rotate(-45deg);
}
.section.navigation .mobile-menu ul {
  position: absolute;
  left: 0;
  top: 168.5px;
  background: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  text-align: center;
  height: 0;
  opacity: 0;
  padding: 0;
  transition: 0.3s;
  overflow: hidden;
  z-index: 9999999;
  list-style: none;
}
.section.navigation .mobile-menu.active ul{
  height: var(--doc-height);
  opacity: 1;
  padding: 50px;
}
.section.navigation .mobile-menu ul li {
  margin-bottom: 20px;
} 
.section.navigation .mobile-menu ul li a{
  font-size: 30px;
  font-family: var(--font-header);
  color: var(--offwhite)!important;
  position: relative;
} 
.section.navigation .mobile-menu ul li a:after{
  content: '';
  width: 100%;
  height: 3px;
  background-color: var(--green);
  position: absolute;
  bottom: 0;
  left: 0;
  transition-duration: .3s;
}
@media(max-width: 1200px) {
  .section.navigation {
    position: relative;
    background: rgba(254,254,254, 1);
    border-bottom: 1px solid var(--offwhite);
  }
}
@media (max-width: 767px) {
  .section.navigation p.logo{
    font-size: 35px;
    margin-top: -5px;
    white-space: nowrap;
  }
  .section.navigation p.sub{
    font-size: 14px;
  }
  .section.navigation .main-menu {
    display: none;
  }
  .section.navigation .mobile-menu {
    display: block
  }
}
/*** END NAV SECTION ***?
/*** HERO SECTION ***/
.section.hero{
  background: linear-gradient(180deg, rgba(250,250,250, 0) 0%,  rgba(250,250,250, 0) 50%, rgba(216,216,216, .5) 70%, var(--offwhite) 100%), url('./assets/images/wes-lewis-couch.webp') center top/cover no-repeat;
  height: 100vh;
  max-height: 990px;
  max-width: 1760px;
  margin: auto;
}
.section.hero::before{
  content: '';
  background: linear-gradient(90deg, var(--offwhite) 0%, rgba(250,250,250, 0) 5%,  rgba(250,250,250, 0) 95%, var(--offwhite) 100%) center/cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.section.hero .content{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.section.hero .content a{
  font-size: 50px;
  color: var(--gray)!important;
}
@media(min-width: 1760px) {
  .section.hero {
    background-size: 1760px;
  }
}
/*** END HERO SECTION ***/
/*** MEDIA SECTION ***/
.section.media .owl-carousel .media-item {
  padding: 20px 0;
}
.section.media .owl-carousel .media-item .media-wrap{
  margin-right: 30px;
}
.section.media .owl-carousel .media-item .media-container{
  position: relative;
  padding-bottom: 56.25%;
}
.section.media .owl-carousel .media-item .media-container iframe{
  box-shadow: 0 0 10px var(--black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 10px;
}
.section.media .owl-carousel .media-item .media-description *{
  text-align: left;
}
@media (max-width:767px) {
  .section.media .owl-carousel .media-item .media-wrap{
    margin: 0;
  }
  .section.media .owl-carousel .media-item .media-container iframe{
    margin: 0;
  }
  .section.media .owl-carousel .media-item .media-description {
    margin-top: 30px;
  }
  .section.media .owl-carousel .media-item .media-description {
    margin-top: 30px;
  }
}
/*** END MEDIA SECTION ***/
/*** GALLERY SECTION ***/
.section.gallery .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
.section.gallery .owl-carousel .gallery-item {
  padding: 11px;
}
.section.gallery .owl-carousel .gallery-item img{
  box-shadow: 0 0 10px var(--black);
}
/*** END GALLERY SECTION ***/
/*** CONTACT SECTION ***/
.section.contact .socials{
  display: flex;
  justify-content: center;
  padding: 30px;
}
.section.contact .socials .social-item {
  margin: 20px;
}
.section.contact .socials .social-item a.icon svg{
  font-size: 40px;
  background: var(--yellow);
  padding: 20px;
  border-radius: 50%;
  color: var(--gray);
  min-width: 40px;
  transition: .3s;
}
.section.contact .socials .social-item a.icon:hover svg{
  background: var(--green);
}
.section.contact .socials .social-item a.icon svg path{
  color: var(--gray);
  transition: .3s;
}
.section.contact .socials .social-item a.icon:hover svg path{
  color: var(--white);
} 

@media (max-width: 767px) {
  .section.contact .socials{
    flex-flow: column nowrap;
  }
}
/*** END CONTACT SECTION ***/
/*** COPYRIGHT SECTION ***/
.section.copyright {
  padding: 0 0 20px 0;
}
/*** END COPYRIGHT SECTION ***/
